.height-screen-ad-css {
    height:80vh;
}
.background-gray-css {
    background-color: rgb(62, 5, 99);
    
    
}



.button-newsletter-css {
    /*
    position: absolute;
    top: 20px; /* Adjust based on your layout needs 
    right: 20px; /* Adjust based on your layout needs 
    */
    background-color: rgb(244, 221, 105); /* White with 50% opacity */
    border-color: white;
    border-width: 1.3px;
    padding: 10px 20px;
    font-size: 16px;
    color: #000; /* Text color */
    cursor: pointer;
    border-radius: 10px;
    transition: background-color 0.3s;
    width: 80%;
    
    
}
.button-newsletter-css:hover {
    background-color: rgba(170, 253, 15, 0.998);
}
.align-left-css {
    text-align:left;
}
.input-size-css {
    
    height:50px;
}
.link-type-infoSec-css {
    text-decoration: none;
    font-size: 30px;
    
}
.column-css {
    direction: column;
}
.no-underline-css {
    text-decoration: none;
}