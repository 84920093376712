




.transparent-button {
    /*
    position: absolute;
    top: 20px; /* Adjust based on your layout needs 
    right: 20px; /* Adjust based on your layout needs 
    */
    background-color: rgba(255, 255, 255, 0); /* White with 50% opacity */
    border-color: white;
    border-width: 1.3px;
    padding: 10px 20px;
    font-size: 16px;
    color: #000; /* Text color */
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
    
}
.transparent-button:hover {
    background-color: #7ccde8; /* Light blue color when hovered */
  }

.button-try-for-free-css {
    
    
    background-color: rgba(247, 184, 242, 0.8); /* White with 50% opacity */

    border-color: white;
    border-width: 1.3px;
    padding: 10px 20px;
    font-size: 16px;
    color: #000; /* Text color */
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
    width: 130px;

}

.button-try-for-free-css:hover {
    background-color: lightcoral;

}
/*  Important!   The style of logo   */ 

  

@media  (max-width: 529px) {
    .logo-css {
        font-size: 20px;
        
    }
    #colButtons.col {
        display: flex;
        justify-content: flex-start; /* Aligns the inner content to the right */
        align-items:center; /* Vertically centers the content if needed */
    }
    #innerColButtons {
        display: flex;
        justify-content: flex-start; /* Aligns the buttons to the left */
    }
    
    .button-container {
        width:100px;
    }
    .button-try-for-free-css {
        display: none;
    }
    .button-width-css {
        width: 70px;
        margin-right: 50px;
    }
    .size-icon-css {
        font-size: 25px;
        
    
    }

    
}
.button-width-css {
    width:100px;
}
.upRightDiv-position-css {
    
    
    
    white-space: nowrap;
    flex-wrap: nowrap; /* Prevents wrapping */
    justify-content: flex-end; /* Aligns items to the right. Change to 'center' for center alignment */
    
    align-items: center; /* This will vertically center the buttons if the div's height is greater than the buttons' height */

}
.button-container {
    display: grid; /* Enables CSS Grid */
    grid-auto-flow: column; /* Arranges items in a single row */
    gap: 10px; /* Adds spacing between buttons */
    width: 200px; /* Ensures the container remains same width */
}


.link-type-css {
   
    
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    align-items: center;
    display: flex;
    position: relative;
    
    text-decoration: none;
    color:white;
    text-align: center;
    max-width: 100%;
    font-family: Telka, sans-serif;
    font-size: 1.33rem;
    font-weight: 400;
    
    
    transition: 0.5s;   /*  for glowing*/
    letter-spacing: 1.5px;
    background-color: transparent;
    cursor:pointer;
    
}




.border-bottom-when-hover-css {
    transition: border-bottom 0.5s ease; /* Smooth transition for the border change */
}

.border-bottom-when-hover-css:hover {
    /*border-bottom: 4px solid #fcf9f9; /* Adjust the color and thickness as needed */
    /*text-transform: uppercase;*/
    padding-bottom: 5px;
    
}
/* Apply hover effect directly to the link */
.link-type-css:hover {
    

    
    text-shadow: 0 0 10px #fff,
                 0 0 20px #fff,
                 0 0 40px #fff,
                 0 0 80px #fff,
                 0 0 120px #fff,
                 0 0 160px #fff; 
                 
    background-color: transparent; /* Ensure no background color */
    font-weight: 500px;
    
}


.width-of-link-css {
    max-width:250px;
    white-space:nowrap;
    min-width:110px;
    text-align: center;
    


}

.row {
    flex-wrap: nowrap;
}
.custom-row {
    width: 100%;

    flex-wrap: nowrap;
    white-space: nowrap;
    width: 100%;
    justify-content: space-between;
    
    
    
}
.row-children-css {
    height:100%;
    width: 100%;
}
@media (min-width:530) {
    /* Ensure the outer div aligns its content to the right */
#colButtons {
    display: flex;
    justify-content: flex-end; /* Aligns the inner content to the right */
    align-items: center; /* Vertically centers the content if needed */
}

/* Ensure the inner div aligns its buttons to the right */
#innerColButtons {
    display: flex;
    justify-content: flex-end; /* Aligns the buttons to the right */
}
}


.custom-d-flex {
    flex-wrap: nowrap;
    white-space: nowrap;
}


/* Column styling */


#colLogo {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    width:auto; /* Equivalent to col-1 */
    min-width:200px;
    max-width: 200px;
}



#colButtons {
    display: flex;
    justify-content:flex-end;
    width: auto; /* Equivalent to col-4 */
    min-width: 200px;
   
    max-width:400px;
}



#colNavMenu {
    
    width: 45%; /* Equivalent to col-6 */
    max-width: 500px;
    flex-grow:1;
    justify-content: center;
    min-width: 500px;

    
}

.size-icon-css {
    font-size: 35px;
    

}
/***********************new to check ********************************/

.menu-dropdown-css {

    display: none;
    background-color: rgb(245, 168, 168) ;
    transition: 0.3s;
    
}
.appearBelowParentDiv-css {
    top:100%;   /* top of the div start from the bottom of it's parent div*/
    left:0%;
}
.menu-level-0 {
    position: relative;
}


.menu-level-1-n {
    display:none;
    background-color: green;
    box-shadow: 0px 8px 16px rgba(0,0,0,0.2); /* Optional: Add a shadow for better visibility */
    z-index: 1000; /* Ensure the dropdown menu appears above other content */
    cursor:pointer;
    }
.menu-level-1 {
    
    top: 100%;
    left:0%;
}

.menu-level-2, .menu-level-3 {
    left:100%;
}

.menu-item-div:hover > .menu-dropdown-css,.display-property-css,
.menu-level-1:hover > .menu-level-2,
.menu-level-2:hover > .menu-level-3 {
    display: block;
}

.border-bottom-when-hover-css:hover {
    padding-bottom: 5px;
    color: #fff;
    text-shadow: 0 0 10px #fff,
                 0 0 20px #fff,
                 0 0 40px #fff,
                 0 0 80px #fff,
                 0 0 120px #fff;
}

/******************************************************************************/




.text-dropdown-css {
    font-size: 20px;
    letter-spacing:2px;
    line-height: 45px;
}

a {
    cursor: pointer;
}
.dropdown-div-css a {
    text-decoration:none;
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 40px;
    font-size: large;
    
}
.text-style-sublist-css:hover {
    font-weight: bold;
}

.dropdown-inner-div-css {
    top:100%;
    bottom:auto;
    width: auto;
    min-width:20vw;
    height:auto;
    z-index: 10000;
    
    /*opacity: 100%;*/
    transition: 0.1s;       /*transition*/
    position: absolute;
    background-color: rgba(10, 10, 10, 0.779) ;
    
    
    border-radius: 10px;
    
    
    
}