
:root {
    --scroll: 100%; /* Default value, to ensure the default size of videoWrapper is 100% of it's parent div */
    --video-scale:1;  /* Default value */
    --video-opacity: 1; /* Default value */
}

.transparent-button {
    /*
    position: absolute;
    top: 20px; /* Adjust based on your layout needs 
    right: 20px; /* Adjust based on your layout needs 
    */
    background-color: rgba(255, 255, 255, 0); /* White with 50% opacity */
    border-color: white;
    border-width: 1.3px;
    padding: 10px 20px;
    font-size: 16px;
    color: #000; /* Text color */
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}


.background-home-css {
   
    
        width: 100vw;
        
       
        height: auto;
        
        
        /*background: url('../Asset/image/image_background.jpeg') no-repeat center center fixed;*/
        
        background-size: cover;
        
        z-index: -1;
        overflow-x: hidden;
        

}





.top-bar-height-css {
    height: 20px;
}
.custom-row {
    margin-right: 0;  /* No !important if this selector is specific enough */
} 



/*   NEW    */

.video-wrapper {
    
    justify-content: center;
    align-items: center;

    display: flex;
    
    top: 0;


    
    display:block;
    margin-left:auto;
    margin-right:auto;
    margin-top: 0;
    
    width: 100%;
    
    height: 100%; /* Full height of the viewport 100vh*/
    
    overflow: hidden;/* Prevents scrolling */
    z-index: -100;
    background-size: cover;
    object-fit:cover;
    
    

    /*shrinking when scroll*/
    
    min-height:500px;
    
    transition: height 3s ease, width 3s ease;/* Adjust the duration and easing function as needed */
    transform:scale(var(--video-scale));
}

/* Aspect ratio for wide screens */
@media (min-width: 1200px) {
    .video-frame {
        width: 100%; /*100vw*/
        height: auto;
    }
    .topic-in-video {
        max-width: 100%;
    }
    .topic-in-video span {
        width:100%;
        height:auto;
        
    }
    .font-topic-lg-css {
        font-size: 160px;
        font-weight: 500px;
    }
    .font-topic-md-css {
        font-size:50px;
    }
}

/* Aspect ratio for medium screens */
@media (min-width: 768px) and (max-width: 1199px) {
    #videoWrapper {
        width: auto; /*100vw*/
        height: 100vh;/*100vh*/
    }
    #backgroundVideo {
        width: auto; /*100vw*/
        height: 100%;/*100vh*/
        
    }
    .topic-in-video {
        max-width: 100%;
    }
    .topic-in-video span {
        width:100%;
        
        height:auto;
    }
    .font-topic-lg-css {
        font-size: 100px;
        font-weight:200px;
    }
    .font-topic-md-css {
        font-size:30px;
    }
}

/* Aspect ratio for small screens */
@media (min-width: 530px) and (max-width: 767px) {
    #videoWrapper {
        width: auto; /*100vw*/
        height: 100vh;/*100vh*/
    }
    #backgroundVideo {
        width: auto; /*100vw*/
        height: 100%;/*100vh*/
        
    }
    .topic-in-video {
        max-width: 100%;
    }
    .topic-in-video span {
        width:100%;
        height:auto;
    }
    .font-topic-lg-css {
        font-size: 70px;
        font-weight:100px;
    }
    .font-topic-md-css {
        font-size:20px;
    }
}
/* Aspect ratio for small screens */
@media (min-width: 330px) and (max-width: 529px) {
    #videoWrapper {
        width: auto; /*100vw*/
        height: 100vh;/*100vh*/
       
    }
    #backgroundVideo {
        width: auto; /*100vw*/
        height: 100%;/*100vh*/
        
    }
    .topic-in-video {
        max-width: 100%;
    }
    .topic-in-video span {
        width:100%;
        height:auto;
    }
    .font-topic-lg-css {
        font-size: 45px;
        font-weight:150px;
        
    }
    .topic-in-video span {
        letter-spacing: 5px;
    }
    .font-topic-md-css {
        font-size:15px;
    }
}



.video-frame {
    display:block;
    margin-left:auto;
    margin-right:auto;
    
    margin-top: 0;


    height: 100%;
    width: 100%; /* Adjust the width based on the aspect ratio */
    transition: height 5s ease, width 5s ease; /* Adjust the duration and easing function as needed */

    opacity: var(--video-opacity);
    transform: scale(var(--video-scale));
    
    
    
    min-width: 469.1px;
    min-height: 632.2px;
    
    object-fit: contain;  /* Ensures the video's content shrinks proportionatly in relation to the change of height */
    background-size: cover;
    
    
    overflow: hidden;

    transition: height 2s ease, width 2 ease; /* Adjust the duration and easing function as needed */
    
    object-position: center; /* Ensure the video content is centered */

}

/***************************IMPORTANT*********************************************/
#backgroundVideo {
    width: 100%;
    height: 100vh; /* Ensure full height on small devices */
    object-fit: cover; /* Cover the entire screen */
    object-position: center; /* Center the video content */
}
/*******************************************************************************/
/*
.top-menu {
    width: 100%;
    position: fixed;
    top: 0;
    background-color: rgba(255, 165, 0, 0); 
    color: white;
    z-index: 100;
    transition: background-color 0.5s linear;
}
*/


.navBar {
    display: flex;
    height: 14%;
    width: 100%;  
    position: fixed;
    right:0;
    top: 0;
    left: 0;
    z-index: 99999; /* Higher value to ensure it's above the video */
    transition: background-color 0.3s ease;
    background-color: transparent; /* Start as transparent */
    border-bottom: 1px solid  white;    /* Add a white border to top bar*/

    flex-wrap: nowrap;
    
    
}









/* When the page is scrolled and navbar needs to change */
.black-background {
    background-color: rgba(0, 0, 0, 1); /* Semi-transparent to solid color transition */
    
}

/*************Home Page Big Video Title********************************/
@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Stencil+Text:wght@100..900&display=swap');
/*<uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 100 to 900 */

.big-shoulders-stencil-text-fontBigTheme {
  font-family: "Big Shoulders Stencil Text", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100px;
  font-style: normal;
}

/**********************************************************************/
.topic-in-video {
    z-index: 10000;
    
    position:absolute;
    top:50%;
    transition: 0.3s;
    
    
    left: auto;
    color:rgb(241, 233, 222);
    object-fit: cover;
    
}
.topic-in-video span {
    
    
    font-family: sans-serif;
    text-transform: uppercase;
    letter-spacing: 10px;
    
   
    
}

.element-in-center-css {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
}