.background-color-css {
    background-color: black;
    
    
}
.img-expenseai-css {
    border-radius: 10px;
    width: 450px;
    
    
}

.font-title-css {
    font-family: Telka, sans-serif;
    
    letter-spacing: 3px;
    margin:auto;/*center text virtically*/
    
}
.text-lg-css {
    font-size: 70px;
    font-weight: 3px;
}
.text-sm-css {
    font-size:30px;
    font-weight:normal;
    
}
.height-screen-ad-css {
    height:80vh;
}



h2, p {
    text-align: left; /* Align the text to the left */
}
.icon-feature-css {
    font-size: 40px;
    
}

.direction-adjustable-row-css {
    display: flex;
    flex-direction: row; /* Default to horizontal layout */
    align-items: center; /* Align items vertically centered */
    padding: 1rem;
}

@media (min-width: 1200px) {
    text-lg-css {

        font-size: 70px;
    }
    .text-sm-css {
        font-size: 30px;
    }

    .feature-text-lg-css h2 {
        font-size:35px;
    }
    .feature-text-sm-css {
        font-size:20px;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .text-lg-css {

        font-size: 50px;
    }
    .text-sm-css {
        font-size: 25px;
    }

    .feature-text-lg-css h2 {
        font-size:35px;
    }
    .feature-text-sm-css {
        font-size:20px;
    }
}


@media (min-width: 530px) and (max-width: 767px) {
    .text-lg-css {

        font-size: 30px;
    }
    .text-sm-css {
        font-size: 18px;
    }

    .feature-text-lg-css h2 {
        font-size:20px;
    }
    .feature-text-sm-css {
        font-size:15px;
    }

    
}

@media (min-width: 280px) and (max-width: 529px) {
    .text-lg-css {
        font-size: 20px;

    }
    .text-sm-css {
        font-size: 12px;
    }
    /*feature text*/
    .feature-text-lg-css h2 {
        font-size:15px;
    }
    .feature-text-sm-css {
        font-size:10px;
    }
    /*feature direction*/
    .direction-adjustable-row-css {
        direction: column;
       
        align-items: center; /* Align items vertically centered */
    
        flex-wrap: wrap;
        
        display: flex;
        flex-direction: column; /* Default to horizontal layout */
        
        padding: 1rem;
    }
     /*feature direction*/
    .direction-adjustable-row-css div {
        width: fit-content;
    }
}