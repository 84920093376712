.background-color-css {
    background-color: black;
}
.img-expenseai-css {
    border-radius: 10px;
    width: 450px;
    ;
    
}
.text-top-layer-css {
    z-index: 99999;
}


.font-title-css {
    font-family: Telka, sans-serif;
    font-size: 1.33rem;
    font-weight: 400;
    
}