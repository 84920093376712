.background-color-AboutUs-css {
    background-color: rgb(33, 3, 46, 1);
    height:100%;
    width: 100vw;
    
}
.justify-content-center-css {
    justify-content: center;
}

@import url('https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap');

.tenor-sans-regular {
    font-family: "Tenor Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
.padding-top-page-non-video-css {
    padding-top:150px;
}

.text-color-css{
    color: rgb(244, 243, 231);
}
.text-style1-css {
    letter-spacing: 2px;
    font-weight: 2px;
    font-size:22px;
    
}
.divAboutUs1  {
    width: 100%;
    text-align:center;
    padding-top:150px;
    padding-bottom: 30px;
    justify-content: center;
    
    
}

.div-width-aboutUs-css {
    width:40%;
}

.height-screen-aboutUs-css {
    height:70vh;
}

.text-size-secondary-center-title {
    font-size:30px;
    font-weight:80px;
    letter-spacing: 5px;
}
.letter-spacing2-css {
    letter-spacing: 5px;
}
.padding-bottom-css-5px {
    padding-bottom: 5px;
}
.founder-portrate1 {
    border-radius: 20px;
    overflow: hidden;
    width: 50%;
    height: auto;
    
}
#divImage {
    display: flex;
    justify-content: center;
    align-items: center;
}
.center-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.responsive-text-div-css {
    text-align: center;
    
}
.padding-responsive-text-css {
    padding:5px;
}

@media print {
    /* All your print styles go here */
    #header,
    #footer,
    .text-color-css {
        color:black;    }
  }

  @media (min-width: 1200px)  {
    .responsive-text-div-css {
        width:100%;
        
    }
    .padding-responsive-text-css {
        padding:5px;
    }

  }
@media (min-width: 768px) and (max-width: 1199px) {
    .founder-portrate1 {
       
        
        width: 65%;
        
    }
    .responsive-text-div-css {
        width:100%;
    }

    .padding-responsive-text-css {
        padding:5px;
    }
}
@media (min-width: 530px) and (max-width: 767px) {
    .founder-portrate1 {
       
        
        width: 100%;
        
    }
    .responsive-text-div-css {
        width:100%;
    }
    .padding-responsive-text-css {
        padding:none;
    }
    .text-style1-css {
        font-size:18px;
    }
    .text-size-secondary-center-title {
        font-size: 20px;

    }
}
@media (min-width: 280px) and (max-width: 529px) {
    .founder-portrate1 {
      
       
        width: 100%;
       
    }
    .responsive-text-div-css {
        width:100%;
    }
    .padding-responsive-text-css {
        padding:none;
    }
    .text-style1-css {
        font-size:15px;
    }
    .text-size-secondary-center-title {
        font-size: 20px;
        font-weight:bold;
    }
}
