/*import the style.css in the logos folder.*/
@import "../../../../src/Logos/Logo_ExpenseAI.tsx/style.css";
:root {
    
    
    
    --left-right-margin: 8vw;
    --frame-opacity-level: 0.65;
}

.frame-backgroud-color-white {
    background-color: white ;
    opacity: 0.75;
    border-radius:15px;
    margin-left: var(--left-right-margin); /* 10% of the viewport width */
    margin-right: var(--left-right-margin); /* 10% of the viewport width */
    margin-top: 30px;
    margin-bottom: 30px;
    
    
}


.currency-input-container {
    display: flex;
    align-items: center;
}

.border-style-divider-css {
    border-right-style:solid;
    border-color: blueviolet;
    

}
.profile-div-frame {
    background-color: white ;
    opacity: 0.75;
    width: 30vw;
    border-radius: 5px;
    margin-left: var(--left-right-margin)
}

@media (min-width: 730px) and (max-width: 1200px) {
    .frame-backgroud-color-white {
        width: 50vw;
    }
    .profile-div-frame {
        width:50vw;
    }

}
@media (min-width: 530px) and (max-width: 729px) {
    .frame-backgroud-color-white {
        width: 80vw;
    }
    .profile-div-frame {
        width:80vw;
    }

}
@media (min-width: 280px) and (max-width: 529px) {
    .frame-backgroud-color-white {
        width: 80vw;
    }
    .profile-div-frame {
        width:80vw;
    }

}

.profile-greating-css {
    font-size:30px;
}
.font-weight-hello-css {
    font-weight:500;
}
.width-small-css {
    width: 80px;
}
.css-height-of-div {
    height: 100px;
    
}
.submit-button-long-css{
    width: 300px;
    margin:20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 7px;
    border-color:rgb(173, 255, 47, 0.5);
    background-color:rgb(173, 255, 47, 0.5);
    
}

.text-size-s {
    font-size: small;
}

.button-customized-small {

}

.btn-red-css {
    background-color: darkorange;
    color: white;
    border-radius: 7px;
    border-color: coral;
    padding-top:3%;
    padding-bottom: 3%;
    font-weight: bold;
}
.btn-blue-css {
    background-color:darkturquoise;
    color: white;
    border-radius: 7px;
    border-color:aquamarine;
    padding-top:3%;
    padding-bottom: 3%;
    font-weight: bold;
    
}
