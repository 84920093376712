


.full-screen-css {
    width: 100vw;
    min-height:100vh;
}


@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@100..700&family=Yanone+Kaffeesatz:wght@200..700&display=swap');

.antonio-font-signIn {
    font-family: "Antonio", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-size:50px;
    color:rgb(248, 221, 187);
    


}
.flex-column-frame-css {
    max-width: 350px;
    width:100%;
    display: flex;
    
    flex-direction: column;
    justify-content: center;
    
}

.center-content-css {
    
    display: flex;
    
    justify-content: center;
}
.width-input-css {
    width:100%;
}

.input-style-css {
    background-color: rgba(233, 223, 242, 0.4);
    border-radius: 5px;
    height: 35px;
    color:rgb(248, 221, 187);
    
    
}
.background-color-signin {
    background-color:rgba(33, 7, 60, 0.927);
}
.input-outline-css {
    outline: 2px solid rgb(248, 221, 187) transparent;
    outline-offset: 2px;
}
.input-style-css:active {
    outline: 2px none rgb(246, 160, 160) transparent;
    outline-offset: 2px;
    
}
.width-100-of-parent {
    width: 100%;
}
@media (min-width: 530px) and (max-width: 767px){
    .flex-column-frame-css {
        width: 100%;
    }
    .width-input-css {
        width:100%;
    }
    .title-sign-in-responsive {
        font-size: 40px;
    }
}
@media (max-width:529px) {
    .width-input-css {
        width:100%;
    }
    .flex-column-frame-css {
        width: 100%;
    }
    .title-sign-in-responsive {
        font-size: 30px;
    }

}
.button-sign-in {
    background-color: rgba(255, 255, 255, 0.5); /* White with 50% opacity */
    border-color: rgb(236, 218, 252);
    border-width: 1.3px;
    padding: 10px 20px;
    font-size: 16px;
    color: rgb(248, 221, 187); /* Text color */
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
    width: 100%;
    font-weight: bold;

    transition-timing-function: ease-out;    
    
}
.margin-top-button {
    margin-top: 18px;
}
.flex-align-same-line {
    display: flex;
    align-items: center; /* Align items vertically centered */
}
.inline-nowrap-css {
    white-space: nowrap; /* Prevents the text from wrapping */
}
.text-color-signin {
    color: rgb(248, 221, 187);
}
.button-sign-in:hover {
    background-color: rgb(248, 186, 123) ;
}
.button-sign-in:active {
    background-color: rgb(201, 129, 58) ;
}
.align-items-start-css {
    align-items: flex-start;
}
.align-self-center-css {
    align-self: center; /* Centers the input field horizontally within the parent */

}
.flex-col-css {
    display: flex;
    
    flex-direction: column;
}
    
.width-of-div-input {
    width: 100%;
}
.text-success-css {
    color:chartreuse;
}
.button-dialog-close-css {
    background-color: transparent;
    border-color: transparent;

}
.button-dialog-close-css:hover {
    color:rgba(231, 191, 12, 0.904);
}

