.logo-css {
    color: rgb(74, 23, 150);
    text-shadow: 0 0 10px rgb(254, 253, 254),
                 0 0 20px rgb(184, 123, 246),
                 0 0 20px rgb(241, 228, 252),
                 0 0 30px rgb(252, 251, 252);
                 
                 
    letter-spacing: 1.5px;
    
    
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    cursor: pointer;
}

.nowrap-text {
    white-space: nowrap;
}
.no-decoration {
    text-decoration: none;
}

@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Sans:ital,wght@0,100..800;1,100..800&display=swap');
/* <uniquifier>: Use a unique and descriptive class name
 <weight>: Use a value from 100 to 800 
 Affected sections:  user profile font user home page after logging in */
    
 
 .custom-font {
    font-family: 'Ubuntu Sans', sans-serif;
}
@media  (min-width: 530px) {
    .font-size-logo-large {
        font-size:30px;
        
    }

    
}

@media  (max-width: 529px) {
    .font-size-logo-large {
        font-size: 20px;
        
    }

    
}

